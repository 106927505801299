import { Highlight } from 'react-instantsearch/dom';
import { h, render, Component } from 'preact';
import { flow, map, upperFirst } from 'lodash/fp';

import { intersperse } from '../utils/fp';

import { Thumbnail } from './Thumbnail';
import { DebugTag } from './DebugTag';

export class ProductCategory extends Component {
  render (props) {
    const {
      hit,
      hit: { title, route, categoryPath },
    } = props;

    return (
      <a class="search-widget--hits-item" href={route}>
        <DebugTag>Product Category</DebugTag>
        {/*<div class="search-widget--hits-item--title">
          <Highlight attributeName="title" hit={hit} />
        </div>*/}
        <div class="search-widget--hits-item--description">
          {flow(
            map(CategoryPathSegment),
            intersperse(RightChevron)
          )(categoryPath)}
        </div>
      </a>
    );
  }
}

const CategoryPathSegment = name => (
  <div class="search-widget--prod-cat-hit--path-segment">{upperFirst(name)}</div>
)

const RightChevron = () => (
  // from https://commons.wikimedia.org/wiki/File:Feather-arrows-chevron-right.svg
  <svg class="search-widget--prod-cat-hit--chevron-icon"
    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
    <polyline points="9 18 15 12 9 6"/>
  </svg>
)
