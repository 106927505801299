import './vendor/algoliasearch.css';
import './styles.css';
import 'algoliasearch';
import { h, render } from 'preact';
import { InstantSearch, SearchBox } from 'react-instantsearch/dom';
import { Search } from './components/Search';
import { apiKey, appId } from './config';

export const mountApp = (target: Element) => {
  return render((
    <InstantSearch appId={appId} apiKey={apiKey} indexName="website">
      <SearchBox />
      <Search />
    </InstantSearch>
  ), target);
};
