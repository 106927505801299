import { Configure, Index, Pagination } from 'react-instantsearch/dom';
import HitsComponent from 'react-instantsearch/src/components/Hits';
import { connectAutoComplete, connectStateResults, connectHits } from 'react-instantsearch/connectors';
import { h, render, Component } from 'preact';
import { memoize, flow, sortBy, slice, identity } from 'lodash/fp';

import { HitComponent } from './HitComponent';
import { Zendesk } from './Zendesk';

const Hits = connectHits(props => {
  const { transformItems = identity, hits } = props;
  return HitsComponent({
    ...props,
    hits: transformItems(hits)
  });
});

const MAX_ITEMS = 8;
const limitItems = slice(0, MAX_ITEMS);
const sortItems = flow(limitItems, sortBy('type'));

function SearchView ({ currentRefinement }) {
  if (currentRefinement.length === 0)
    return null;
  else
    return (
      <div class="search-hits">
        <Configure hitsPerPage={MAX_ITEMS} />
        <div class="search-hits-section pull-left">
          <div class="search-widget--hits-section--title">Search Results</div>
          <Hits hitComponent={HitComponent}
            transformItems={sortItems} />
          <EmptyView />
        </div>
        <div class="search-hits-section pull-right">
          <Index indexName="zendesk_designashirt_articles">
            <Configure hitsPerPage={MAX_ITEMS} />
            <div class="search-widget--hits-section--title">Help</div>
            <EmptyView />
            <Hits hitComponent={Zendesk}
              transformItems={sortItems} />
          </Index>
        </div>
        <Pagination />
      </div>
    );
}

// FIXME: Add no results at all view.
const EmptyView = connectStateResults(({
  searchState: { query },
  searchResults
}) => {
  if (searchResults === null) return;
  const { hits = [] } = searchResults;
  if (hits.length === 0)
    return (
      <div class="search-widget--hits-item">
        No results for {query}.
        <div>Please visit our <a href="https://support.designashirt.com/hc/en-us/articles/360007888813-Didn-t-find-what-you-re-looking-for-">Help Center</a></div>
      </div>
    );
  else
    return null;
});

export const Search = connectAutoComplete(SearchView);

