import { Highlight } from 'react-instantsearch/dom';
import { h, render, Component } from 'preact';

import { Thumbnail } from './Thumbnail';
import { DebugTag } from './DebugTag';

const colorRootUrl = 'https://s3-us-west-1.amazonaws.com/media.designashirt.com/Products/Designer/';
const dasRootUrl = 'http://www.designashirt.com';

function ColorSwatch ({ url = null, onMouseOver, onMouseOut }) {
  if (url)
    return (
      <div class="search-widget--hits-item--color-swatch"
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        style={`background-image:url(https://s3-us-west-1.amazonaws.com/media.designashirt.com/ColorSwatches/${url})`} />
    );
  else
    return null;
}

export class Product extends Component {
  constructor () {
    super();
    this.state = { hoveredColor: null };
  }

  changeColor (hoveredColor = null) {
    return () => {
      this.setState({ hoveredColor });
    }
  }

  render (props, state) {
    const {
      hit,
      hit: { title, route, thumbnail, colors = [] },
    } = props;
    const { hoveredColor } = state;

    const showThumbnail = hoveredColor ?
      colorRootUrl + hoveredColor.front :
      dasRootUrl + thumbnail;

    return (
      <a class="search-widget--hits-item" href={route}>
        <DebugTag>Product</DebugTag>
        <Thumbnail url={showThumbnail} />
        <div class="search-widget--hits-item--title"
          title={hit.title} >
          <Highlight attributeName="title" hit={hit} /><br />
        </div>
        <div class="search-widget--hits-item--description">
          <div class="search-widget--hits-item--colors-list">
            {colors.map(c => (
              <ColorSwatch url={c.swatch}
                onMouseOver={this.changeColor(c)}
                onMouseOut={this.changeColor()} />
            ))}
          </div>
        </div>
      </a>
    );
  }
}
