import { h, render, Component } from 'preact';

import { Product } from './Product';
import { ProductCategory } from './ProductCategory';
import { LandingPage } from './LandingPage';

export const HitComponent = result => {
  switch (result.hit.type) {
    case 'product': return <Product {...result} />;
    case 'productCategory': return <ProductCategory {...result} />;
    case 'landingPage': return <LandingPage {...result} />;
    default:
      console.error('Error rendering hit result:', result);
      return null;
  }
}


