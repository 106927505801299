import { Highlight, Snippet } from 'react-instantsearch/dom';
import { h, render, Component } from 'preact';

// import { Thumbnail } from './Thumbnail';
import { DebugTag } from './DebugTag';

export function Zendesk({hit}) {
  const { id, title } = hit;
  return (
    <a class="search-widget--hits-item" href={`https://www.designashirt.com/hc/en-us/articles/${id}`}>
      <DebugTag>Help</DebugTag>
      <div class="search-widget--hits-item--title"
        title={hit.title} >
        <Highlight attributeName="title" hit={hit} />
      </div>
      <div class="search-widget--hits-item--description">
        <Snippet attributeName="body_safe" hit={hit} />
      </div>
    </a>
  );
}
