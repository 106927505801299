import { h, render, Component } from 'preact';

export function DebugTag({ children }) {
  // return null;
  if (process.env.NODE_ENV === 'production') return null;
  return (
    <div class="search-widget--hits-item--debug">{children}</div>
  );
}

