import { h, render, Component } from 'preact';

export function Thumbnail ({ path = null, url = null }: { path?: null | string; url?: null | string; }) {
  if (path)
    return (
      <div class="search-widget--hits-item--image"
        style={`background-image:url(https://www.designashirt.com${path})`} />
    );
  else if (url)
    return (
      <div class="search-widget--hits-item--image"
        style={`background-image:url(${url})`} />
    );
  else
    return null;
}

