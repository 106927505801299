import { Highlight, Snippet } from 'react-instantsearch/dom';
import { h, render, Component } from 'preact';

import { Thumbnail } from './Thumbnail';
import { DebugTag } from './DebugTag';

export class LandingPage extends Component {
  render (props) {
    const {
      hit,
      hit: { title, url, splashImage },
    } = props;

    return (
      <a class="search-widget--hits-item" href={url}>
        <DebugTag>Landing page</DebugTag>
        <Thumbnail url={splashImage} />
        <div class="search-widget--hits-item--title"
          title={hit.title} >
          <Highlight attributeName="title" hit={hit} /><br />
        </div>
        <div class="search-widget--hits-item--description">
          <Snippet attributeName="description" hit={hit} />
        </div>
      </a>
    );
  }
}
