import { mountApp } from './app';

const mountTargetSelector = '#search-box';
const target = window.document.querySelector(mountTargetSelector);
if (target) {
  console.info('installing search', target);
  mountApp(target);
}
else {
  console.error('unable to mount because mount target not found "%s"', mountTargetSelector);
}
